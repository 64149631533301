import {handleFetch, handleSave, HttpMethod, handleUpdate} from './api.js';

const URL = `${process.env.REST_API_BASE}/ops/configuration/stations`;

const getStations = async () => {
  const data = await handleFetch(URL, HttpMethod.GET);
  return data;
};
const getStationByPobId = async (pobId) => {
  const data = await handleFetch(`${URL}/${pobId}`);
  return data;
};

export const getAllStations = async () => {
  const allStations = await handleFetch(`${process.env.REST_API_BASE}/station/v3/stations/${process.env.REST_API_ENV}/fi`);
  return allStations && allStations.stations ? allStations.stations : [];
};
export const updateFoodStation = async (pobId, payload) => {
  const data = await handleUpdate(`${URL}/${pobId}`, {station: payload});
  return data;
};
export const saveFoodStation = async (payload) => {
  const data = await handleSave(URL, {station: payload});
  return data;
};
export const saveFoodStationsConfig = async (payload) => {
  const data = await handleUpdate(URL, {config: payload});
  return data;
};
export default {getStations, getStationByPobId, getAllStations, updateFoodStation, saveFoodStation, saveFoodStationsConfig};